import React from 'react';
import Text, { TextTypes } from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import { Col } from 'ni-ui/layout';
import styles from './styles.scss';
import quickResponseIcon from '../../../images/quick_response.svg';

const { quickResponseContainer, textAndIconContainer } = styles;

function UkhesheScanToPay() {
  return (
    <div className={quickResponseContainer}>
      <Col gutter={false} span={4} sm={7} md={4}>
        <div className={textAndIconContainer}>
          <img src={quickResponseIcon} alt="qrIcon" />
          <Text
            textKey="UKHESHE_SCAN_TO_PAY"
            textColor={COLORS.GREY_VERY_DARK}
            type={TextTypes.MENU_LEVEL_2}
          />
        </div>
      </Col>
    </div>
  );
}

export default UkhesheScanToPay;
