import React, { useContext, lazy, Suspense, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Col, Container, Row } from 'ni-ui/layout';
import Switch, { Case } from './components/switch';
import { AppContext } from './app-context';
import locale from './locale';
import Fallback from './components/fallback';
import styles from './styles.scss';
import useDocumentTitle from './components/hooks/useDocumentTitle';
import BrandingHeader from './components/branding-header';
import { getRedirectToMerchantUrl } from './common/utils';

const PaymentPage = lazy(() => import('./payment-page'));
const PaymentStatusPage = lazy(() => import('./payment-status-page'));
const ThreeDSOne = lazy(() => import('./components/three-ds/three-ds-one'));
const ThreeDSTwo = lazy(() => import('./components/three-ds/three-ds-two'));
const PartialAuthConfirmationPage = lazy(() => import('./partial-auth-page'));

const getDocumentTitle = (condition, orderDetails) => {
  if (condition === 'STARTED' || condition === 'AWAIT_3DS' || condition === 'MCP_FAILED') {
    const { currencyCode, formattedValue } = orderDetails.order.amount;
    return `Please pay ${currencyCode} ${formattedValue}`;
  }
  return 'Payment result';
};

const Pages = () => {
  const { orderDetails, languageDirection, customFallBackMsg } = useContext(AppContext);
  const threeDsData = useMemo(
    () => (
      orderDetails.order.threeDs
        ? { threeDs: orderDetails.order.threeDs, threeDs2: null }
        : { threeDs2: orderDetails.order.threeDs2, threeDs: null }
    ),
    [orderDetails]
  );

  const condition = useMemo(() => {
    if (orderDetails.state === 'AWAIT_3DS' && threeDsData.threeDs2 === null) {
      return 'AWAIT_3DS_1';
    }
    if (orderDetails.state === 'AWAIT_3DS' && threeDsData.threeDs === null) {
      return 'AWAIT_3DS_2';
    }
    return orderDetails.state;
  }, [orderDetails, threeDsData]);

  const { order = { merchantAttributes: {} } } = orderDetails;
  const { merchantAttributes: { redirectUrl = '', skipConfirmationPage = 'false' } } = order;
  const shouldRedirect = skipConfirmationPage === 'true';
  const merchantRedirectUrl = useMemo(() =>
    getRedirectToMerchantUrl(orderDetails), [redirectUrl, skipConfirmationPage]);

  const docTitle = useMemo(() => getDocumentTitle(condition, orderDetails), [
    condition,
    orderDetails
  ]);
  useDocumentTitle(docTitle);

  return (
    <IntlProvider
      locale={orderDetails.order.language}
      messages={locale[orderDetails.order.language]}
    >
      <div
        className={styles.page}
        dir={languageDirection}
        lang={orderDetails.order.language}
      >
        <div className={`${styles.main} ${styles.mainContainer}`} role="main">
          <Container>
            <Row>
              <Col span={8} sm={12} md={10} offset={{ lg: 4, md: 2, sm: 0 }}>
                <BrandingHeader isPaymentPage />
                <Switch condition={condition}>
                  <Case id={['AWAIT_3DS_1']}>
                    {() => (
                      <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                        <ThreeDSOne threeDsData={threeDsData.threeDs} />
                      </Suspense>)}
                  </Case>
                  <Case id={['STARTED', 'PENDING', 'REATTEMPT_STARTED', 'MCP_FAILED', 'AWAIT_3DS_2']}>
                    {() => {
                      if (customFallBackMsg) {
                        return <Fallback message="CUSTOM_PENDING_ANNI_PAYMENT_MESSAGE" />;
                      }
                      return (
                        <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                          <ThreeDSTwo state={condition} threeDsData={threeDsData.threeDs2}>
                            <PaymentPage />
                          </ThreeDSTwo>
                        </Suspense>
                      );
                    }}
                  </Case>
                  <Case id={['CAPTURED', 'AUTHORISED', 'PURCHASED', 'VERIFIED', 'PARTIALLY_AUTHORISED']}>
                    {() => {
                      if (shouldRedirect) {
                        window.location.replace(merchantRedirectUrl);
                        return <Fallback message={customFallBackMsg || 'NORMAL_LOADING_TEXT'} />;
                      }
                      return (
                        <Suspense fallback={<Fallback message={customFallBackMsg || 'NORMAL_LOADING_TEXT'} />}>
                          <PaymentStatusPage status="SUCCESS" />
                        </Suspense>
                      );
                    }}
                  </Case>
                  <Case id={['FAILED', 'POST_AUTH_REVIEW', 'CANCELLED', 'PARTIAL_AUTH_DECLINED', 'PARTIAL_AUTH_DECLINE_FAILED']}>
                    {() => {
                      if (shouldRedirect) {
                        window.location.replace(merchantRedirectUrl);
                        return <Fallback message="NORMAL_LOADING_TEXT" />;
                      }
                      return (
                        <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                          <PaymentStatusPage status={condition} />
                        </Suspense>
                      );
                    }}
                  </Case>
                  <Case id={['AWAITING_PARTIAL_AUTH_APPROVAL']}>
                    {() => (
                      <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                        <PartialAuthConfirmationPage />
                      </Suspense>)}
                  </Case>
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </IntlProvider>
  );
};

export default Pages;
