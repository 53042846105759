import React, { useContext, useState, Fragment, Suspense, useMemo, useEffect, lazy, useCallback } from 'react';
import { Option } from 'ni-ui/radio';
import get from 'lodash/get';
import Colors from 'ni-ui/colors';
import { Alert, NotificationType } from 'ni-ui/notification';
import { Row, Col } from 'ni-ui/layout';
import Text, { TextTypes } from 'ni-ui/text';
import { PropTypes } from 'prop-types';
import httpClient from 'ni-ui/httpClient';
import { AppContext } from '../../../app-context';
import styles from './styles.scss';
import { usePaymentMethodSetter } from '../payment-methods';
import SplitPaymentCard from './split-payment-card';
import { minorToMajorUnitsFromCurrencyCode } from '../../../utils';
import cardLogo from '../../../images/card.svg';
import dashCircle from '../../../images/dash-circle.svg';
import plusCircle from '../../../images/plus-circle.svg';

const Card = lazy(() => import('../card/card'));

const SplitPayment = ({ savedCards }) => {
  const { orderDetails, setContextState } = useContext(AppContext);
  const [splitPaymentCards, setSplitPaymentCards] = useState(savedCards);

  const { currencyCode, value } = orderDetails.order.amount;
  const [cardsSelected, setCardsSelected] = useState([]);
  const [isSubChoiceChecked, setSelectedSubChoice] = useState(false);
  const totalAmount = useMemo(() => cardsSelected.reduce(
    (amount, card) => (card.amount ? card.amount.value + amount : amount),
    0
  ), [cardsSelected]);

  const splitPaymentCardsLimit = get(orderDetails, 'splitPaymentCardsLimit');
  const [remainingAmount, setRemainingAmount] = useState(orderDetails.order.amount);
  const calculatedAmount = useMemo(() => value - totalAmount, [cardsSelected]);

  useEffect(() => {
    setRemainingAmount(calculatedAmount >= 0 ? calculatedAmount : 0);
  }, [cardsSelected]);
  useEffect(() => {
    if (splitPaymentCards.length >= splitPaymentCardsLimit) {
      setSelectedSubChoice(false);
    }
  }, [splitPaymentCards]);

  const isMissingCvvInSelectedCards = cardsSelected.filter(SelectedCard =>
    !!SelectedCard.cvv && SelectedCard.cvv.length === 3).length !== cardsSelected.length;

  const onClickPayNow = useCallback(async () => {
    const { outletId, reference } = orderDetails.order;
    const splitPaymentBody = cardsSelected.map(card => ({
      cardholderName: card.cardholderName,
      ...(card.isAddedCard && { pan: card.pan }),
      expiry: card.expiry,
      cvv: card.cvv,
      ...(card.isAddedCard && { name: card.scheme }),
      ...(card.isAddedCard && { saveMyCard: card.recaptureCsc }),
      amount: card.amount,
      ...(!card.isAddedCard && { cardToken: card.cardToken })
    }));

    const { data } = await httpClient.post(
      `/api/outlets/${outletId}/orders/${reference}/payments/${orderDetails.order.paymentReference}/split-payment`,
      { cards: splitPaymentBody }
    );
    setContextState({
      orderDetails: {
        ...orderDetails,
        state: data.state,
        order: data.order,
      },
    });
  }, [orderDetails.order, cardsSelected]);

  const selectedPaymentMethodName = usePaymentMethodSetter({
    onClickPayNow,
    disablePayNow: (!!calculatedAmount) || isMissingCvvInSelectedCards,
    id: 'SPLIT_PAYMENT'
  });
  const CheckBoxElement = (
    <div
      tabIndex={0}
      role="button"
      onClick={() => setSelectedSubChoice(!isSubChoiceChecked)}
      onKeyPress={() => setSelectedSubChoice(!isSubChoiceChecked)}
    >
      <img src={!isSubChoiceChecked ? plusCircle : dashCircle} alt="Add new card" width="27" height="27" />
    </div>
  );

  const balance = minorToMajorUnitsFromCurrencyCode(remainingAmount, currencyCode);

  return (
    <Fragment>
      <Row key={orderDetails.order.paymentReference} gutter={false}>
        <Col gutter={false}>
          <Option label="split-payment" id="SPLIT_PAYMENT">
            <div className={styles.headerContainer}>
              <Col gutter={false} span={4} sm={7} md={4}>
                <div className={styles.holder}>
                  <img src={cardLogo} alt="Cards" />
                  <Text
                    textKey="SPLIT_PAYMENT"
                    textColor={Colors.GREY_VERY_DARK}
                    type={TextTypes.MENU_LEVEL_2}
                  />
                </div>
              </Col>
            </div>
          </Option>
          <Suspense fallback={<h1>Loading..</h1>}>
            {selectedPaymentMethodName === 'SPLIT_PAYMENT' && (
              <Fragment>
                {splitPaymentCards.map((card, index) => (
                  <SplitPaymentCard
                    card={card}
                    index={index}
                    cardsSelected={cardsSelected}
                    setCardsSelected={setCardsSelected}
                    balance={remainingAmount}
                  />
                ))}

                <Row>
                  <div className={styles.balanceAdjustmentWrapperStyle}>
                    <Col span={8} md={12} sm={12}>
                      {calculatedAmount < 0 ? (
                        <Alert
                          type={NotificationType.ERROR}
                          textKey="SPLIT_PAYMENT_ADJUSTMENT_ERROR"
                        />
                      ) : null}
                    </Col>

                    <Col span={4} md={12} sm={12}>
                      {selectedPaymentMethodName === 'SPLIT_PAYMENT' && (
                        <div className={styles.remainingAmountText}>
                          <Text
                            textKey="SPLIT_PAYMENT_REMAINING"
                            values={{
                              remainingAmount: balance,
                              currency: currencyCode,
                            }}
                            textColor={Colors.GREY_VERY_DARK}
                            type={TextTypes.MENU_LEVEL_1}
                          />
                        </div>
                      )}
                    </Col>
                  </div>
                </Row>

                <div
                  className={`${styles.splitPaymentCardCheckbox}  ${
                    styles.splitPaymentCard
                  } ${// rest of unselected cards  - No. of added cards
                    (cardsSelected.length >= splitPaymentCardsLimit ||
                      splitPaymentCardsLimit - cardsSelected.length <=
                        splitPaymentCards.length - savedCards.length) &&
                    styles.splitPaymentAddCardDisabled
                  } `}
                >
                  <Card
                    id="SPLIT_PAYMENT"
                    isSubChoice
                    inputType="checkbox"
                    isSubChoiceChecked={isSubChoiceChecked}
                    CheckBoxElement={CheckBoxElement}
                    cardHeaderTitle="USE_NEW_CARD"
                    setSplitPaymentCards={setSplitPaymentCards}
                    splitPaymentCards={splitPaymentCards}
                  />
                </div>

              </Fragment>
            )}
          </Suspense>
        </Col>
      </Row>
    </Fragment>
  );
};

SplitPayment.defaultProps = {};

SplitPayment.propTypes = {
  savedCards: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SplitPayment;
